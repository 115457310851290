@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Gradients */
  .orangeGradient {
    @apply from-[#FF935F] to-darkOrange;
  }

  .darkOrangeGradient {
    @apply from-orange to-[#6F2300];
  }

  .darkGrayGradient {
    @apply from-gray-500 to-[#42464e];
  }

  .yellowOrangeGradient {
    @apply from-[#FFC149] to-orange;
  }

  .whiteGrayGradient {
    @apply bg-gradient-to-r from-white to-[#F3F3F3A1];
  }

  /* Converts the image color to #808080, if you want to change the color go to this website https://codepen.io/sosuke/pen/Pjoqqp */
  .convertImageColor {
    @apply filter invert-[51%] sepia-[6%] saturate-[22%] hue-rotate-[347deg] brightness-[97%] contrast-[87%];
  }

  /* Cards OUR WORK */
  .cardOurWork {
    @apply transition-all duration-500 px-4 sm:px-12 py-8 sm:py-12 h-full rounded-3xl shadow-xl box-border;
  }
}

/* FONTS */
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf")
      format("woff2 supports variations"),
    url("../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

@font-face {
  font-family: "TitilliumWeb-Bold";
  src: url("../public/fonts/TitilliumWeb/TitilliumWeb-Bold.ttf");
}

@font-face {
  font-family: "TitilliumWeb-Black";
  src: url("../public/fonts/TitilliumWeb/TitilliumWeb-Black.ttf");
}

/* CUSTOM SCROLLBAR */
/* width */
/* ::-webkit-scrollbar {
  width: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #1b1b1b;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #F15A14;
  border-radius: 0.5rem;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #CC4C11;
} */

/* CUSTOM SCROLLBAR END*/

/* Put the text only with color on the borders */
.textTransparent {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
}

/* CARDS */
/* Card1 */
.borderGradient1 {
  background: linear-gradient(
    240deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(33, 33, 33, 0) 50%
  );
  background: -moz-linear-gradient(
    240deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    240deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardGradient1 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    198deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: -webkit-linear-gradient(
    198deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: linear-gradient(
    198deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.bottomGradient1 {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    0deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: linear-gradient(
    0deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

.contact-meeting-container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

@media (max-width: 767px) {
  .contact-meeting-container {
    padding: 48px;
  }
}

@media (min-width: 768px) {
  .contact-meeting-container {
    padding: 24px;
    padding-top: 0;
  }
}

.contact-meeting {
  background-image: url("./../public/images/form/meeting.jpg");
  background-size: cover;
  border-radius: 0.75em;
  flex: 1;
}

/* Card2 */
.borderGradient2 {
  background: linear-gradient(
    -60deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    -60deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    -60deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardGradient2 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    90deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

/* Card3 */
.borderGradient3 {
  background: linear-gradient(
    40deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    40deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardGradient3 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    40deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    40deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  background: linear-gradient(
    40deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.bottomGradient3 {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    -100deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: -webkit-linear-gradient(
    -100deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: linear-gradient(
    -100deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

/* Card4 */
.cardGradient4 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    -20deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: -webkit-linear-gradient(
    -20deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: linear-gradient(
    -20deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.bottomGradient4 {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    -95deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: -webkit-linear-gradient(
    -95deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: linear-gradient(
    -95deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

/* Card5 */
.borderGradient5 {
  background: linear-gradient(
    115deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    115deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    115deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardGradient5 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    -20deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: -webkit-linear-gradient(
    -20deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  background: linear-gradient(
    -10deg,
    rgba(77, 43, 27, 1) 0%,
    rgba(32, 32, 32, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.bottomGradient5 {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    -95deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: -webkit-linear-gradient(
    -95deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  background: linear-gradient(
    70deg,
    rgba(32, 32, 32, 0.9) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

/* Card 6 */
.borderGradient6 {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    85deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    85deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    85deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.bottomGradient6 {
  background: rgba(32, 32, 32, 0.5);
}

.cardGradient6 {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  background: -webkit-linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  background: linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.testemonailGradient {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  background: -webkit-linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  background: linear-gradient(
    25deg,
    rgba(77, 43, 27, 1) 15%,
    rgba(32, 32, 32, 0.1) 80%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.borderGradient1:before,
.borderGradient2:before,
.borderGradient3:before,
.borderGradient4:before,
.borderGradient5:before,
.borderGradient6::before,
.borderGradientNone:before {
  position: absolute;
  border-radius: 0.75rem;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    240deg,
    rgba(255, 130, 71, 1) 100%,
    rgba(33, 33, 33, 0) 0%
  );
  z-index: -999;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.borderGradient1:hover::before,
.borderGradient2:hover::before,
.borderGradient3:hover::before,
.borderGradient4:hover::before,
.borderGradient5:hover::before,
.borderGradient6:hover::before,
.borderGradientNone:hover::before {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .testemonailGradient {
    background: rgb(77, 43, 27);
    background: -moz-linear-gradient(
      25deg,
      rgba(77, 43, 27, 1) 15%,
      rgba(32, 32, 32, 0.7) 80%
    );
    background: -webkit-linear-gradient(
      25deg,
      rgba(77, 43, 27, 1) 15%,
      rgba(32, 32, 32, 0.7) 80%
    );
    background: linear-gradient(
      25deg,
      rgba(77, 43, 27, 1) 15%,
      rgba(32, 32, 32, 0.7) 80%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
  }
}

/* Card Meet Diogo */
.borderDiogoGradient {
  background: linear-gradient(
    90deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(255, 130, 71, 0) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardDiogoGradient {
  background: rgb(112, 52, 24);
  background: -moz-linear-gradient(
    90deg,
    rgba(112, 52, 24, 1) 50%,
    rgba(77, 43, 27, 0) 70%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(112, 52, 24, 1) 50%,
    rgba(77, 43, 27, 0) 70%
  );
  background: linear-gradient(
    90deg,
    rgba(112, 52, 24, 1) 50%,
    rgba(77, 43, 27, 0) 70%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

/* Form */
.borderGradientForm {
  background: linear-gradient(
    -55deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -moz-linear-gradient(
    -55deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  background: -webkit-linear-gradient(
    -55deg,
    rgba(255, 130, 71, 1) 0%,
    rgba(32, 32, 32, 0) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8247", endColorstr="#202020", GradientType=1);
}

.cardGradientForm {
  background: radial-gradient(
    circle at top left,
    rgba(152, 71, 34, 1) 0%,
    rgba(32, 32, 32, 1) 50%
  );
  background: -webkit-radial-gradient(
    ellipse at top left,
    rgba(152, 71, 34, 1),
    rgba(32, 32, 32, 1)
  );
  background: -moz-radial-gradient(
    ellipse at top left,
    rgba(152, 71, 34, 1),
    rgba(32, 32, 32, 1)
  );
}

.bottomGradientForm {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    270deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 70%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 70%
  );
  background: linear-gradient(
    270deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

.bottomGradientCard {
  background: rgb(32, 32, 32);
  background: -moz-linear-gradient(
    0deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 70%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 70%
  );
  background: linear-gradient(
    0deg,
    rgba(32, 32, 32, 1) 0%,
    rgba(32, 32, 32, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#202020", endColorstr="#202020", GradientType=1);
}

.tableBlack {
  border-radius: var(--corner-radius-corner-l, 16px);
  border: 1px solid #2f2f2f;
  background: #232323;
  box-shadow: 0px -2px var(--spacing-spacing-xs, 12px) 0px rgba(17, 17, 17, 0.35);
}

.gradient-ball {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1300px;
  height: 500px;
  filter: blur(100px);
  border-radius: 50%;
  background: radial-gradient(circle, #7d3818 0%, #000000 100%);
}

.gradient-ball2 {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  height: 500px;
  filter: blur(100px);
  border-radius: 50%;
  background: radial-gradient(circle, #7d3818 0%, #000000 100%);
}

.dionamites img:nth-child(1) {
  transform: translate(0%);
}

.dionamites img:nth-child(2) {
  transform: translate(-40%);
}

.dionamites img:nth-child(3) {
  transform: translate(20%);
}

.dionamites img:nth-child(4) {
  transform: translate(100%);
}

.animation1 {
  animation-name: animeDionamite1;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-delay: 1s;
}

@keyframes animeDionamite1 {
  0% {
    left: 0px;
    opacity: 0;
  }

  60% {
    left: 64px;
    opacity: 1;
  }

  75% {
    left: 78px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation2 {
  animation-name: animeDionamite2;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-delay: 1s;
}

@keyframes animeDionamite2 {
  0% {
    left: 7.5px;
    opacity: 0;
  }

  35% {
    left: 46px;
    opacity: 1;
  }

  45% {
    left: 53px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation3 {
  animation-name: animeDionamite3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 35s;
}

@keyframes animeDionamite3 {
  0% {
    left: 0px;
    opacity: 0;
  }

  50% {
    left: 50px;
    opacity: 1;
  }

  55% {
    left: 57px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation4 {
  animation-name: animeDionamite4;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 20s;
}

@keyframes animeDionamite4 {
  0% {
    left: 0px;
    opacity: 0;
  }

  20% {
    left: -50px;
    opacity: 1;
  }

  30% {
    left: -30px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation5 {
  animation-name: animeDionamite5;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 10s;
}

@keyframes animeDionamite5 {
  0% {
    left: 0px;
    opacity: 0;
  }

  40% {
    left: 44px;
    opacity: 1;
  }

  55% {
    left: 68px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation6 {
  animation-name: animeDionamite6;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 25s;
}

@keyframes animeDionamite6 {
  0% {
    left: 0px;
    opacity: 0;
  }

  60% {
    left: 48px;
    opacity: 1;
  }

  70% {
    left: 56px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.animation7 {
  animation-name: animeDionamite7;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

@keyframes animeDionamite7 {
  0% {
    left: 0px;
    opacity: 0;
  }

  40% {
    left: 48px;
    opacity: 1;
  }

  55% {
    left: 66px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 0;
  }
}

.bannerGradient {
  background: rgb(77, 43, 27);
  background: -moz-linear-gradient(
    0deg,
    #f26a2b 15%,
    rgb(255, 118, 55, 0.4) 95%
  );
  background: -webkit-linear-gradient(
    0deg,
    #f26a2b 15%,
    rgb(255, 118, 55, 0.4) 95%
  );
  background: linear-gradient(0deg, #f26a2b 15%, rgb(255, 118, 55, 0.4) 95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d2b1b", endColorstr="#202020", GradientType=1);
}

.borderFormSubmited {
  animation: border 8s linear infinite;
}

@keyframes border {
  50% {
    border-color: #fff;
  }

  100% {
    border-color: #fff;
  }
}

.top,
.bottom,
.left,
.right {
  position: absolute;
  background: #ff8247;
  animation: move 8s linear infinite;
}

.top {
  top: -3px;
  left: -3px;
  height: 3px;
  animation-name: top;
}

.bottom {
  bottom: -3px;
  right: -3px;
  height: 3px;
  animation-name: bottom;
}

.left {
  bottom: -3px;
  left: -3px;
  width: 3px;
  animation-name: left;
}

.right {
  top: -3px;
  right: -3px;
  width: 3px;
  animation-name: right;
}

@keyframes top {
  25% {
    width: 0;
    background: #ff8247;
  }

  38% {
    width: calc(100% + 3px);
    background: #ff8247;
  }

  50% {
    background: #ff8247;
  }

  62% {
    background: #ff8247;
  }

  75% {
    width: calc(100% + 3px);
    background: #ff8247;
  }

  88% {
    left: auto;
    right: -3px;
    width: 0;
    background: #ff8247;
  }
}

@keyframes bottom {
  0% {
    width: 0;
  }

  12% {
    width: calc(100% + 3px);
    background: #ff8247;
  }

  25% {
    background: #ff8247;
  }

  38% {
    background: #ff8247;
  }

  50% {
    width: calc(100% + 3px);
    background: #ff8247;
  }

  62% {
    left: -3px;
    width: 0;
    background: #ff8247;
  }

  75% {
    background: #ff8247;
  }
}

@keyframes left {
  12% {
    height: 0;
    background: #ff8247;
  }

  25% {
    height: calc(100% + 3px);
    background: #ff8247;
  }

  38% {
    background: #ff8247;
  }

  62% {
    height: calc(100% + 3px);
    background: #ff8247;
  }

  75% {
    top: -3px;
    height: 0;
    background: #ff8247;
  }

  88% {
    background: #ff8247;
  }
}

@keyframes right {
  38% {
    height: 0;
    background: #ff8247;
  }

  50% {
    height: calc(100% + 3px);
    background: #ff8247;
  }

  62% {
    background: #ff8247;
  }

  75% {
    background: #ff8247;
  }

  88% {
    height: calc(100% + 3px);
    background: #ff8247;
  }

  100% {
    top: auto;
    bottom: -3px;
    height: 0;
    background: #ff8247;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(2px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-2px);
  }
}

.shake-text {
  display: inline-block;
  animation: shake 2.8s ease infinite;
}

.Toastify__toast-theme--dark {
  background: radial-gradient(
    circle at top left,
    rgba(152, 71, 34, 1) 0%,
    rgba(32, 32, 32, 1) 105%
  ) !important;
  background: -webkit-radial-gradient(
    ellipse at top left,
    rgba(152, 71, 34, 1),
    rgba(32, 32, 32, 1)
  ) !important;
  background: -moz-radial-gradient(
    ellipse at top left,
    rgba(152, 71, 34, 1),
    rgba(32, 32, 32, 1)
  ) !important;
}

.Toastify__toast-icon > svg {
  width: 100%;
  height: 100%;
  fill: white;
}

.fade-in {
  animation: fadein 0.3s ease-in-out forwards;
}

.fade-out {
  animation: fadeout 0.3s ease-in-out forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
