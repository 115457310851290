.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  background: #1b1b1b;
}

.spinner {
  width: 300px;
  text-align: center;
}

.spinner > div {
  width: 36px;
  height: 36px;
  background-color: #f15a14;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animationUp {
  z-index: 999;
  animation: animationup 1.5s linear forwards;
}

@keyframes animationup {
  0% {
    top: 0vh;
  }

  70% {
    top: 0vh;
  }

  80% {
    top: -20vh;
  }

  100% {
    top: -100vh;
    display: none;
  }
}

.animationLogo {
  animation: animationlogo 1.5s linear forwards;
}

@keyframes animationlogo {
  0% {
    opacity: 100%;
  }

  25% {
    opacity: 50%;
  }

  50% {
    opacity: 100%;
  }

  70% {
    opacity: 50%;
  }
  90% {
    opacity: 0%;
    display: none;
  }
  100% {
    opacity: 0%;
    display: none;
  }
}

.hero1 {
  animation: animationHero 2.5s ease-out forwards;
  animation-delay: -400ms;
}

@keyframes animationHero {
  0% {
    transform: translateY(50px);
    opacity: 0%;
  }
  30% {
    opacity: 0%;
  }
  70% {
    transform: translateY(0%);
    opacity: 100%;
  }
}

.hero2 {
  animation: animationHero2 2.5s ease-out normal;
  animation-delay: -200ms;
}

@keyframes animationHero2 {
  0% {
    transform: translateY(50px);
    opacity: 0%;
  }
  30% {
    opacity: 0%;
  }
  70% {
    transform: translateY(0%);
    opacity: 100%;
  }
}

.hero3 {
  animation: animationHero3 2.5s ease-out forwards;
  animation-delay: -100ms;
}

@keyframes animationHero3 {
  0% {
    transform: translateY(50px);
    opacity: 0%;
  }
  30% {
    opacity: 0%;
  }
  70% {
    transform: translateY(0%);
    opacity: 100%;
  }
}
